import React, { useState, useEffect } from 'react';
import {
    APIProvider,
    Map,
    AdvancedMarker,
    InfoWindow
} from '@vis.gl/react-google-maps';
import logo from '../assets/images/Marker_Icon.png';
import provinceData from '../data/provinceData';

const containerStyle = {
    width: '100%',
    height: '100%'
};

// const mainRestId = 7;
// const fetchUrl="https://devapi.iorders.online/customer/retrieve_restaurants";
// const mainRestId = 142;
// const fetchUrl="https://api.iorders.online/customer/retrieve_restaurants";
const orderOnlineLink="https://orders.iorders.online/";
const orderDeliveryLink = (storeTitle) => {
  if (storeTitle === "p-lovepizza-leduc") {
    return "https://www.order.store/ca/store/lovepizza-leduc/1HeZhJyCVP-3e1XX5eq3fw";
  }
  if (storeTitle === "p-lovepizza-spruce-grove") {
    return "https://www.skipthedishes.com/love-pizza-spruce-grove";
  }
  if (storeTitle === "p-lovepizza-heritage-valley") {
    return "https://www.ubereats.com/ca/store/lovepizza-heritage-valley/CQSxii7OXQS5vzEYzF3oew?diningMode=PICKUP&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMjI1MTglMjBFJTIwUXVhbmNlJTIwU3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjIyZDQzOGJhNC02NDc0LTkyMTQtZTNmOS0zYWM2NWQ5ZWMzYWIlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIydWJlcl9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjQ0MzY1MzMlMkMlMjJsb25naXR1ZGUlMjIlM0EtMTA0LjU0ODA2MDIlN0Q%3D&ps=1";
  }
  if (storeTitle === "p-lovepizza-canterra") {
    return "https://www.order.store/ca/store/lovepizza-downtown/-txHYqc6UoaBGDQv7zq8Bg?ps=1";
  }
  if (storeTitle === "p-lovepizza-rabbit-hill") {
    return "https://www.order.store/ca/store/lovepizza-rabbit-hill/yO3z-BEsURGwqsEqc7JESA";
  }
  return "https://orders.iorders.online/";
};


const REACT_APP_GOOGLE_MAPS_API_KEY="AIzaSyCUWWPPnxhxx9ozxoMCpvsLwJDXeod-TPU";
const REACT_APP_GOOGLE_MAPS_ID="a02d1cb38e9a0435";

function MyMap({ selectedProvince, coordinates, stores }) {
    const [infoBox, setInfoBox] = useState({ visible: false, content: null, position: null });
    const [center, setCenter] = useState({ lat: 53.5461, lng: -113.4937 });
    const [zoom, setZoom] = useState(11);
    const [mapKey, setMapKey] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!isMobile && selectedProvince && provinceData[selectedProvince]) {
          setCenter({
            lat: provinceData[selectedProvince].lat,
            lng: provinceData[selectedProvince].lng
          });
          setZoom(6); 
          setMapKey(prevKey => prevKey + 1);
        }
      }, [selectedProvince, isMobile]);

      useEffect(() => {
        if (!isMobile && coordinates) {
          setCenter({
            lat: coordinates.lat,
            lng: coordinates.lng
          });
          setZoom(13); // Set zoom to 10 when coordinates are provided
          setMapKey(prevKey => prevKey + 1);
        }
      }, [coordinates, isMobile]);
    useEffect(() => {
        
        if (!isMobile && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                    setZoom(13);
                },
                function(error) {
                    console.error("Error Fetching Location", error);
                }
            );
        }

           
    }, [isMobile]);
    const handleMarkerClick = (marker) => {
        setInfoBox({
            visible: true,
            content: (
                <div id="iw-container">
                    <div className="iw-title">{marker.name}</div>
                    <div className="iw-address">{marker.address}</div>
                    <div className="iw-content">
                        <div className="store-status" style={{ color: marker.status === "Open" ? 'green' : 'red',fontSize:'16px'}}>{marker.status}</div>
                        {marker.status === "Open" && (
                            <div style={{ paddingBottom: '10px' }}>
                            <div style={{ color: 'black',margin:'0px'}}>  Takeout:
                            {marker.takeout === "Takeout Available" ? 
                                <span style={{ color: 'green' , marginLeft: '10px',marginRight: '10px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 15" width="30" height="30" fill="currentColor"><path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path></svg>
                                </span> : 
                                <span style={{ color: 'red' , marginLeft: '10px',marginRight: '10px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 14" width="30" height="30" fill="currentColor"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
                                </span>
                            } 
                            Delivery:
                            {marker.delivery === "Delivery Available" ? 
                                <span style={{ color: 'green' , marginLeft: '10px',marginRight: '10px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 15" width="30" height="30" fill="currentColor"><path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path></svg>
                                </span> : 
                                <span style={{ color: 'red', marginLeft: '10px',marginRight: '10px'  }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 13" width="30" height="30" fill="currentColor"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
                                </span>
                            }
                            </div>
                            </div>
                        )}
                        {marker.status === "Closed" && marker.reopen && (
                            <p className="store-status" style={{ color: 'red' }}>
                                {marker.reopen}
                            </p>
                        )}
                        {marker.distance && (
                            <p style={{ color: 'green', marginTop: '10px' }}>{marker.distance}s Away</p>
                        )}
                        {marker.closingSoon && marker.status === "Open" &&(
                            <p className="store-status" style={{ color: 'orange' }}>
                                {marker.closingSoon}
                            </p>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', width: '100%' }}>
                            <button className="preorder-button" style={{ flex: 1, padding: '10px' }}
                                    onClick={() => window.open(orderOnlineLink+marker.title, '_blank')}>
                                {marker.status === "Open" ? "Order Pickup" : "Pre Order Pickup"}
                            </button>
                            <button className="preorder-button" style={{ flex: 1, padding: '10px' }}
                                    onClick={() => window.open(orderDeliveryLink(marker.title), '_blank')}>
                                {marker.status === "Open" ? "Order Delivery" : "Pre Order Delivery"}
                            </button>
                        </div>
                    </div>
                </div>
            ),
            position: { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) },
            pixelOffset: { width: 0, height: -100 }
        });
    };

    const handleMapClick = () => {
        setInfoBox({ visible: false, content: null, position: null });
    };
    return !isMobile ? (
        <div style={containerStyle} onClick={handleMapClick}>
            <APIProvider apiKey={REACT_APP_GOOGLE_MAPS_API_KEY}>
                <Map key={mapKey} defaultCenter={center} defaultZoom={zoom} gestureHandling={'cooperative'} mapId={REACT_APP_GOOGLE_MAPS_ID}>
                    {stores.map((store, index) => (
                        <AdvancedMarker key={index} position={{ lat: parseFloat(store.lat), lng: parseFloat(store.lng) }} onClick={() => handleMarkerClick(store)}>
                            <span><img src={logo} alt="logo" style={{ width: '60px'}}/></span>
                        </AdvancedMarker>
                    ))}
                    {coordinates && (
                        <AdvancedMarker position={coordinates}>
                            <span><img src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" alt="Selected Location"/></span>
                        </AdvancedMarker>
                    )}
                    {infoBox.visible && (
                        <InfoWindow position={infoBox.position} onCloseClick={handleMapClick} options={{ pixelOffset: new window.google.maps.Size(0, -55) }}>
                            <div style={{ padding: '5px', borderRadius: '5px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)'}}>
                                {infoBox.content}
                            </div>
                        </InfoWindow>
                    )}
                </Map>
            </APIProvider>
        </div>
    ) : (
        <div style={{ padding: '10px', textAlign: 'center' }}>Map is not available on mobile devices.</div>
    );
}
export default MyMap;