const provinceData = {
  SK: { name: "Saskatchewan", lat: 55.000000, lng: -106.000000 },
  PE: { name: "Prince Edward Island", lat: 46.250000, lng: -63.000000 },
  ON: { name: "Ontario", lat: 50.000000, lng: -85.000000 },
  NS: { name: "Nova Scotia", lat: 45.000000, lng: -63.000000 },
  AB: { name: "Alberta", lat: 55.000000, lng: -115.000000 },
  BC: { name: "British Columbia", lat: 53.726669, lng: -127.647621 },
  MB: { name: "Manitoba", lat: 56.415211, lng: -98.739075 },
  NL: { name: "Newfoundland and Labrador", lat: 53.135509, lng: -57.660435 },
  NB: { name: "New Brunswick", lat: 46.498390, lng: -66.159668 },
  QC: { name: "Quebec", lat: 53.000000, lng: -70.000000 }
};

export default provinceData;