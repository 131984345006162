import React, { useState } from 'react';
import './Header.css';
import logo from '../assets/images/logo.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="header">
      <img alt="Logo" src={logo} className="logo" id="header-logo" />
      <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
      ☰
      </div>
      <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
        <a href="https://www.lovepizza.ca/" className="header-link">Home</a>
        <a href="https://www.lovepizza.ca/menu" className="header-link">Menu</a>
        <a href="https://www.lovepizza.ca/franchise" className="header-link">Apply for Franchise</a>
      </nav>
    </header>
  );
}

export default Header;